import React, { useState, useEffect } from 'react';
import {
  string, func, instanceOf, number
} from 'prop-types';
import {
  arrayOf as arrayType,
  params,
  shape,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Typography,
} from '@one-thd/sui-atomic-components';
import { Filters } from '@one-thd/sui-icons';

import { ArticleListingFiltersContent } from './ArticleListingFiltersContent';
import { AppliedFilters } from './AppliedFilters';

const ArticleListingFilters = ({
  categoryID, topicID, filters, type, updateVariables, triggerAnalytics, totalArticles
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('article-listing-filters.ready');
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const currentCheckedFilters = [type, ...filters];

  const variables = {
    categoryID,
    type: type || undefined,
    topicID: topicID || undefined,
    filters: filters && filters.length > 0 ? filters : undefined
  };

  const { data, loading, error } = useDataModel('filters', {
    variables
  });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsOpen(open);
  };

  const toggleChecked = (id) => {
    const idIsGuideType = id?.length === 2;
    if (idIsGuideType) {
      const typeValue = id === type ? undefined : id;
      updateVariables([typeValue, ...filters]);
    } else {
      const newFilters = [...filters];
      const index = newFilters.indexOf(id);
      if (index > -1) newFilters.splice(index, 1);
      else newFilters.push(id);
      updateVariables([type, ...newFilters]);
    }
  };

  return (
    <div className="sui-flex sui-flex-col">
      <div
        className="sui-w-full sui-flex sui-justify-between"
      >
        <div
          className="sui-flex sui-flex-col"
        >
          <Typography
            variant="h4"
            component="p"
          >
            Filters
          </Typography>
          <Typography
            variant="body-lg"
            color="subtle"
          >
            {totalArticles} Result{totalArticles !== 1 ? 's' : ''}
          </Typography>
        </div>
        <div
          className="sui-flex"
        >
          <Button
            variant="secondary"
            onClick={toggleDrawer(true)}
            endIcon={Filters}
          >
            Filter & Sort
          </Button>
        </div>
      </div>
      <div
        className="sui-flex sui-flex-col"
      >
        <AppliedFilters
          currentCheckedFilters={currentCheckedFilters}
          toggleChecked={toggleChecked}
          data={data}
          loading={loading}
        />
      </div>
      <Drawer
        data-testid="filter-sort-drawer"
        open={isOpen}
        onClose={toggleDrawer(false)}
        position="right"
      >
        <DrawerHeader title="Filter & Sort" onClose={toggleDrawer(false)} />
        <DrawerBody>
          <ArticleListingFiltersContent
            triggerAnalytics={triggerAnalytics}
            currentCheckedFilters={currentCheckedFilters}
            toggleChecked={toggleChecked}
            data={data}
            loading={loading}
          />
        </DrawerBody>
        <DrawerFooter>
          <div className="sui-flex sui-gap-2">
            <Button
              fullWidth
              variant="secondary"
              onClick={() => {
                updateVariables([]);
              }}
            >
              Clear All Filters
            </Button>
            <Button
              fullWidth
              onClick={toggleDrawer(false)}
            >
              Apply Filters
            </Button>
          </div>
        </DrawerFooter>
      </Drawer>
    </div>
  );
};

ArticleListingFilters.propTypes = {
  categoryID: string,
  topicID: string,
  updateVariables: func.isRequired,
  triggerAnalytics: func.isRequired,
  totalArticles: number,
  type: string,
  filters: instanceOf(Array)
};

ArticleListingFilters.defaultProps = {
  categoryID: '',
  topicID: '',
  filters: [],
  type: '',
  totalArticles: 0
};

ArticleListingFilters.displayName = 'ArticleListingFilters';

ArticleListingFilters.dataModel = {
  filters: params({
    categoryID: stringType(),
    topicID: stringType(),
    filters: arrayType(stringType()),
    type: stringType()
  }).shape({
    informationTypeCounts: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    brands: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    homeAreas: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    keywords: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    seasons: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    regions: arrayType(shape({
      id: stringType(),
      title: stringType()
    }))
  })
};

export { ArticleListingFilters };
