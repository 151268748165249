import React from 'react';
import { string, func } from 'prop-types';
import { Chip } from '@one-thd/sui-atomic-components';

const Pill = ({ id, label, title, toggleChecked }) => (
  <span className="sui-capitalize" data-testid={'chip-' + id}>
    <Chip title={label} label={title} onClick={() => toggleChecked(id)} />
  </span>
);

Pill.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  title: string.isRequired,
  toggleChecked: func.isRequired
};

export { Pill };
