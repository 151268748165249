import React, { useState, useEffect } from 'react';
import {
  string, func, instanceOf,
} from 'prop-types';
import {
  bool,
  params,
  shape,
  arrayOf as arrayType,
  string as stringType,
} from '@thd-nucleus/data-sources';
import {
  Skeleton,
  SkeletonLine,
  useBreakpoint,
} from '@one-thd/sui-atomic-components';
import classNames from 'classnames';

import { Pill } from './Pill';

const groupLabels = {
  informationTypeCounts: 'Guide Type',
  brands: 'Brand',
  homeAreas: 'Home Area',
  keywords: 'Keyword',
  seasons: 'Season',
  regions: 'Region',
};

const AppliedFilters = ({
  currentCheckedFilters, toggleChecked, data, loading
}) => {
  const [pillsToShow, setPillsToShow] = useState([]);
  const md = useBreakpoint('md');
  const [isSm, setIsSm] = useState(false);

  useEffect(() => {
    setIsSm(md.lesser);
  }, [md]);

  const filterGroups = data?.filters || {};

  useEffect(() => {
    let selectedPills = [];
    Object.keys(filterGroups).forEach((key, indexi) => {
      const group = filterGroups[key];

      if (!Array.isArray(group) || !group.length) return;

      // Seek in the filter group if a selected filter is there, gather it's data and generate Pill
      const filterPillsArr = group
        .filter(({ id }) => !!currentCheckedFilters.includes(id))
        .map(({ title, id }, indexj) => (
          <Pill
            key={indexi + '' + indexj}
            id={id}
            label={groupLabels[key]}
            title={title}
            toggleChecked={toggleChecked}
          />
        ));

      selectedPills = [...selectedPills, ...filterPillsArr];
    });
    setPillsToShow(selectedPills);
  }, [data]);

  if (loading) {
    return (
      <div
        data-component="ArticleListingFilterPillsPlaceholder"
      >
        <SkeletonLine variant="single" fullWidth />
      </div>
    );
  }

  return (
    <div
      data-testid="ArticleListingFilterPills"
      className={
        classNames(
          'sui-flex sui-gap-2',
          { 'sui-overflow-x-auto': isSm },
          { 'sui-flex-wrap': !isSm },
        )
      }
    >
      {pillsToShow}
    </div>
  );
};

AppliedFilters.propTypes = {
  filters: instanceOf(Array),
  currentCheckedFilters: arrayType(string).isRequired,
  toggleChecked: func.isRequired,
  data: shape({
    filters: shape({
      informationTypeCounts: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      brands: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      homeAreas: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      keywords: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      seasons: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      regions: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
    })
  }),
  loading: bool,
};

AppliedFilters.defaultProps = {
  filters: [],
  data: [],
  loading: false,
};

AppliedFilters.displayName = 'AppliedFilters';

AppliedFilters.dataModel = {
  filters: params({
    categoryID: stringType(),
    topicID: stringType(),
    filters: arrayType(stringType()),
    type: stringType()
  }).shape({
    informationTypeCounts: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    brands: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    homeAreas: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    keywords: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    seasons: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    regions: arrayType(shape({
      id: stringType(),
      title: stringType()
    }))
  })
};

export { AppliedFilters };