import React from 'react';
import { instanceOf } from 'prop-types';
import classNames from 'classnames/bind';
import { ArticleDrawer } from '@thd-olt-component-react/article-drawer';
import { Typography } from '@one-thd/sui-atomic-components';
// eslint-disable-next-line @harmony/stencil/no-import-css
import styles from '../article-listing-topics.module.scss';

const MobileTopics = ({ topics }) => {

  const categoryPill = classNames(
    styles['category-pills']
  );

  return (
    <div
      data-testid="mobile-topics"
      data-component="ArticleListingMobileTopics"
    >
      <div className="sui-flex sui-justify-between sui-gap-6 sui-mb-3">
        <div>
          <Typography variant="h4">Suggested Categories</Typography>
        </div>

        <div>
          <ArticleDrawer />
        </div>
      </div>
      <div className={categoryPill}>
        {topics}
      </div>
    </div>
  );
};

MobileTopics.propTypes = {
  topics: instanceOf(Array).isRequired
};

MobileTopics.displayName = 'MobileTopics';

export { MobileTopics };
