/* eslint-disable */
import * as React from "react";
import { QueryProvider } from "@thd-nucleus/data-sources";
import { ArticleListingPage } from "./ArticleListingPage";

export const Home = () => {
  return (
    <QueryProvider cacheKey="home">
      <ArticleListingPage />
    </QueryProvider>
  );
};

Home.propTypes = {};

Home.displayName = "HomePage";

Home.defaultProps = {
  name: "",
};
