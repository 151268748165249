import {
  MetaTag,
  LinkTag,
  ScriptTag,
  TitleTag,
  useHelmet
} from '@thd-nucleus/thd-helmet';
import PropTypes from 'prop-types';
import {
  extend,
  params,
  shape,
  arrayOf,
  string as stringType,
  number as numberType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { getArticleTypePage } from '../helpers/article-helpers';
import {
  getArticleStructuredBreadcrumbData
} from './structured-data/getArticleBuyingGuideStructuredData';
import {
  APPENDED_BRAND_NAME,
  HD_URL,
  OPEN_GRAPH_FACEBOOK_ADMIN
} from '../constants';

const articleListingMetadataHandler = ({ data }) => {
  const isNoFollow = !!data?.articles?.canonicalURL?.includes('NCNI-5');
  const canonical = data?.articles?.metadata?.canonicalURL ? `${HD_URL}${data?.articles?.metadata?.canonicalURL}` : '';
  const siteName = data?.articles?.metadata?.siteName || '';
  const type = getArticleTypePage(data?.articles?.articleType);
  const title = data?.articles?.metadata?.title || '';
  const ogTitle = data?.articles?.metadata?.title || '';
  const description = data?.articles?.metadata?.description || '';

  const tags = [
    new MetaTag({ property: 'og:url', content: canonical, id: 'ogUrl' }),
    new MetaTag({ property: 'og:description', content: description, id: 'ogDescription' }),
    new MetaTag({ property: 'og:site_name', content: siteName, id: 'ogSiteName' }),
    new MetaTag({ property: 'og:title', content: ogTitle, id: 'ogTitle' }),
    new MetaTag({ property: 'og:type', content: type, id: 'ogType' }),
    new MetaTag({ property: 'fb:admins', content: OPEN_GRAPH_FACEBOOK_ADMIN, id: 'fbAdmins' }),
    new TitleTag(title),
    new MetaTag({ name: 'description', content: description, id: 'description' }),
    new LinkTag({ rel: 'canonical', href: canonical, id: 'canonical' })
  ];
  if (data?.articles?.breadcrumbs?.length > 0) {
    tags.push(new ScriptTag(
      { content: getArticleStructuredBreadcrumbData(data.articles.breadcrumbs),
        id: 'articleBreadcrumbStructuredData' }
    ));
  }
  if (isNoFollow) {
    tags.push(new MetaTag({ name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW', id: 'robots' }));
  } else {
    tags.push(new MetaTag({ name: 'robots', content: 'max-image-preview:large', id: 'robots' }));
  }
  return tags;
};

const ArticleListingMetadata = ({ variables }) => {
  const { data, loading } = useDataModel('articles', {
    variables,
  });

  const deps = data?.articles;
  useHelmet('articleListingMetadata', { data }, articleListingMetadataHandler, [deps]);

  return null;
};

ArticleListingMetadata.displayName = 'ArticleListingMetadata';

ArticleListingMetadata.propTypes = {
  variables: PropTypes.shape({
    categoryID: PropTypes.string.isRequired,
    topicID: PropTypes.string,
    page: PropTypes.number,
    limit: PropTypes.number,
    type: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

ArticleListingMetadata.dataModel = extend(
  {
    articles: params({
      categoryID: stringType().isRequired(),
      topicID: stringType(),
      page: numberType(),
      limit: numberType(),
      type: stringType(),
      filters: arrayOf(stringType()),
    }).shape({
      totalPages: numberType(),
      articlesPerPage: numberType(),
      page: numberType(),
      titleText: stringType(),
      totalArticles: numberType(),
      canonicalURL: stringType(),
      metadata: shape({
        description: stringType(),
        siteName: stringType(),
        title: stringType(),
        type: stringType(),
        canonicalURL: stringType(),
        facebookAdmins: stringType(),
      }),
      breadcrumbs: arrayOf(
        shape({
          dimensionName: stringType(),
          url: stringType(),
          label: stringType(),
        })
      ),
    }),
  }
);

export { ArticleListingMetadata };