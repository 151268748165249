import {
  ARTICLE_BUYING_GUIDE_REGEX,
  ARTICLE_INSPIRATION_GUIDE_REGEX,
  ARTICLE_INFLUENCER_GUIDE_REGEX,
  ARTICLE_PROJECT_GUIDE_REGEX
} from '../constants';

export const getArticleTypePage = (type) => {
  if (!type) return '';

  if (ARTICLE_PROJECT_GUIDE_REGEX.test(type)) return 'Article How To';
  if (ARTICLE_BUYING_GUIDE_REGEX.test(type)) return 'Article Buying Guide';
  if (ARTICLE_INSPIRATION_GUIDE_REGEX.test(type)) return 'Article Inspiration';
  if (ARTICLE_INFLUENCER_GUIDE_REGEX.test(type)) return 'Article Influencer';

  return '';
};