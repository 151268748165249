import React, { useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Pill, Typography } from '@one-thd/sui-atomic-components';
import {
  params,
  string as stringType,
  number as numberType,
  bool as boolType,
  useDataModel,
  arrayOf,
  extend
} from '@thd-nucleus/data-sources';
import { ArticleDrawer } from '@thd-olt-component-react/article-drawer';
// eslint-disable-next-line @harmony/stencil/no-import-css
import styles from './article-listing-topics.module.scss';
import { MobileTopics } from './subcomponent/MobileTopics';

const ArticleListingTopics = ({ categoryID }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('article-listing-topics.ready'); }, []);

  const { data, loading, error } = useDataModel('topic', {
    variables: { categoryID },
  });
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  if (!data || loading || error) {
    return (
      <div
        className={styles['article-listing-loading']}
        data-component="ArticleListingTopicsPlaceholder"
      />
    );
  }
  const articleListingTopicsContainer = classNames(
    styles['article-listing-topics']
  );

  const categoryPill = classNames(
    styles['category-pills']
  );

  const topics = data?.topic;

  let topicsArray = Array.isArray(topics) && topics.length && topics.map((topic, index) => {
    return (
      <div key={index}>
        <Pill
          label={topic.title}
          component="a"
          href={topic.url}
          clickable
        />
      </div>
    );
  });

  if (isMobile) {
    return (
      <MobileTopics topics={topicsArray} />
    );
  }

  return (
    <div
      data-testid="article-topics"
      className={articleListingTopicsContainer}
      data-component="ArticleListingTopics"
    >
      <div className="sui-flex sui-justify-between sui-mb-3">
        <div>
          <Typography variant="h4">Suggested Categories</Typography>
        </div>

        <div>
          <ArticleDrawer />
        </div>
      </div>

      <div className={categoryPill}>
        {topicsArray}
      </div>
    </div>
  );
};

ArticleListingTopics.propTypes = {
  categoryID: string.isRequired,
};

ArticleListingTopics.displayName = 'ArticleListingTopics';

ArticleListingTopics.dataModel = extend(
  {
    topic: arrayOf(params({ categoryID: stringType().isRequired() }).shape({
      title: stringType(),
      id: stringType(),
      type: stringType(),
      count: numberType(),
      url: stringType(),
      isCurrentPage: boolType()
    }),
    )
  },
  ArticleDrawer
);

export { ArticleListingTopics };
