import { HD_URL } from '../../constants';
import { addSpaceForBreakTag, stripHtml } from '../metadata-helpers';

export const getArticleBuyingGuideStructuredData = (data) => {
  let schema = {
    '@context': 'https://schema.org',
    '@type': 'Guide',
    inLanguage: 'en-US',
    name: data?.article?.title,
    url: `${HD_URL}${data?.article?.canonicalURL}`,
    description: stripHtml(addSpaceForBreakTag(data?.article?.introduction))
  };

  if (data?.article?.sections) {
    schema.hasPart = data?.article?.sections.map((section) => ({
      '@type': 'Guide',
      name: section?.name,
      text: stripHtml(addSpaceForBreakTag(section?.instruction)),
      reviewAspect: section?.name
    }));
  }

  if (/video/.test(data?.article?.articleHeader?.type)) {
    let embedUrl = data?.article?.articleHeader?.preview?.replace('watch?v=', 'embed/');
    schema.video = {
      '@type': 'VideoObject',
      name: data?.article?.metadata?.title,
      description: stripHtml(addSpaceForBreakTag(data?.article?.metadata?.description)),
      thumbnailUrl: data?.article?.articleHeader?.thumbnail,
      embedUrl: embedUrl?.endsWith('?ref=0') ? embedUrl : embedUrl?.concat('?ref=0')
    };
  }

  if (/image/.test(data?.article?.articleHeader?.type)) {
    schema.image = data?.article?.articleHeader?.source;
  }

  return schema;
};

export const getArticleStructuredBreadcrumbData = (breadcrumbs) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': 'https://www.homedepot.com/',
          name: 'Home'
        }
      },
      ...breadcrumbs.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        position: index + 2,
        item: {
          '@id': `https://www.homedepot.com${breadcrumb?.url || ''}`,
          name: breadcrumb?.label || ''
        }
      }))
    ]
  };
};