import { useState } from 'react';

export function useALPVariables(pathname) {
  let cPath = pathname;
  if (!pathname.startsWith('/c/')) {
    cPath = pathname.substring(pathname.indexOf('/c/'));
  }

  const [, , , , startPageFilters = '', startPage] = cPath.split('/');
  const [startCategoryID, ...optionalParts] = startPageFilters.split('-');
  let startTopicID;
  let startType;
  let startFilters;

  optionalParts.forEach((part) => {
    if (part?.length === 2) startType = part;
    else if (part?.length === 4) startTopicID = part;
    else if (part?.startsWith('Z')) {
      startFilters = part.split('Z').filter((filter) => !!filter);
    }
  });

  const startVariables = {
    categoryID: startCategoryID,
    topicID: startTopicID,
    type: startType,
    filters: startFilters,
    page: parseInt(startPage || '1', 10),
    limit: 24,
  };

  const [variables, setVariables] = useState(startVariables);
  const [popState, setPopState] = useState(undefined);
  const { popVariables } = popState || {};
  const pageVariables = popVariables || variables;

  const handlePopState = ({ state: { popVariables: _popVariables } }) => {
    setPopState({ popVariables: _popVariables });
  };

  const updateVariables = (checkedFilters) => {
    setPopState(undefined);
    const [articleType, ...zFilters] = checkedFilters;
    const checkType = pageVariables.type || '';

    if (articleType !== checkType) {
      setVariables({
        ...pageVariables,
        type: articleType || undefined,
        filters: [],
        page: 1,
      });
      return;
    }

    setVariables({
      ...pageVariables,
      filters: zFilters,
      page: 1,
    });
  };

  return {
    variables,
    pageVariables,
    popState,
    handlePopState,
    updateVariables,
  };
}
