/* eslint-disable */
import React from "react";
import { Image } from "@thd-olt-component-react/core-ui";

const DiyBanner = () => {
  return (
    <div className="article-results__banner" data-component="DiyBanner">
      <a
        href="https://www.homedepot.com/workshops/"
        title="Learn the Basics With our Digital Workshops"
      >
        <Image
          src="https://contentgrid.homedepot-static.com/hdus/en_US/DTCCOMNEW/fetch/DIY_Projects_and_Ideas/DIY-HALF-BNR-digital-workshops-COMP-1.jpg"
          alt="Learn the Basics With our Digital Workshops"
          title="Learn the Basics With our Digital Workshops"
          height={100}
          width={300}
          className="stretchy"
        />
      </a>
    </div>
  );
};

DiyBanner.displayName = "DiyBanner";

DiyBanner.dataModel = {};

export { DiyBanner };
