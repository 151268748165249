export const filterHeaderMapping = {
  informationTypeCounts: 'Guide Type',
  brands: 'Brand',
  homeAreas: 'Home Area',
  keywords: 'Keyword',
  seasons: 'Events & Seasons',
  regions: 'Region',
};

export const SHOW_TEXTS = {
  more: 'Show More',
  less: 'Show Less',
};