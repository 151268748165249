import React, { useEffect } from 'react';
import {
  string, func, instanceOf, number
} from 'prop-types';
import {
  bool,
  params,
  shape,
  object,
  arrayOf as arrayType,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';

import { filterHeaderMapping } from './constants';
import { FilterGroup } from './FilterGroup';

const ArticleListingFiltersContent = ({
  triggerAnalytics,
  currentCheckedFilters, toggleChecked,
  data, loading
}) => {
  const filterGroups = data?.filters || {};
  const filterGroupKeys = Object.keys(filterGroups);

  useEffect(() => {
    const analyticsFilters = {};
    filterGroupKeys.forEach((key) => {
      const group = filterGroups[key];
      if (!Array.isArray(group) || !group.length) return;
      analyticsFilters[key] = group.map((filter) => (
        {
          ...filter,
          isCurrentPage: currentCheckedFilters.includes(filter.id)
        }
      ));
    });
    triggerAnalytics(analyticsFilters);
  }, [filterGroups]);

  return (
    <>
      <div
        data-testid="ArticleListingFiltersContent"
      >
        {
          filterGroupKeys.map((key, index) => (
            <FilterGroup
              key={index}
              loading={loading}
              data-testid="filter-group"
              checkedFilters={currentCheckedFilters}
              toggleChecked={toggleChecked}
              header={filterHeaderMapping[key]}
              filters={filterGroups[key] || []}
              isMobile={false}
            />
          ))
        }
      </div>
    </>
  );
};

ArticleListingFiltersContent.propTypes = {
  triggerAnalytics: func.isRequired,
  filters: instanceOf(Array),
  currentCheckedFilters: arrayType(string).isRequired,
  toggleChecked: func.isRequired,
  data: shape({
    filters: shape({
      informationTypeCounts: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      brands: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      homeAreas: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      keywords: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      seasons: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
      regions: arrayType(shape({
        id: stringType(),
        title: stringType()
      })),
    })
  }),
  loading: bool,
};

ArticleListingFiltersContent.defaultProps = {
  filters: [],
  data: [],
  loading: false,
};

ArticleListingFiltersContent.displayName = 'ArticleListingFiltersContent';

ArticleListingFiltersContent.dataModel = {
  filters: params({
    categoryID: stringType(),
    topicID: stringType(),
    filters: arrayType(stringType()),
    type: stringType()
  }).shape({
    informationTypeCounts: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    brands: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    homeAreas: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    keywords: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    seasons: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    regions: arrayType(shape({
      id: stringType(),
      title: stringType()
    }))
  })
};

export { ArticleListingFiltersContent };
