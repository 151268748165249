/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  oneOfType,
  string,
} from 'prop-types';
import classnames from 'classnames';

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Link,
  Checkbox,
  InputAdornment,
  SelectionControlLabel,
  TextField,
} from '@one-thd/sui-atomic-components';
import { ArrowDown, ArrowUp, Search } from '@one-thd/sui-icons';

import { SHOW_TEXTS } from './constants';

const FilterGroup = ({
  header,
  filters,
  loading,
  toggleChecked,
  checkedFilters
}) => {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [keywordReq, setKeywordReq] = useState('');

  if (!Array.isArray(filters) || !filters.length) return null;

  let filtersToRender = filters;

  if (header === 'Keyword') {
    const videoKeyword = filtersToRender.filter((item) => item.title === 'Video');
    const otherKeywords = filtersToRender.filter((item) => item.title !== 'Video');

    filtersToRender = videoKeyword.concat(...otherKeywords);
  }

  const renderSearch = filtersToRender.length > 10;
  const renderShowAllButton = filtersToRender.length > 5;
  const showAllText = showAllFilters ? SHOW_TEXTS.less : SHOW_TEXTS.more;

  const renderedFilters = filtersToRender.map(({ title, id }, index) => {
    const selected = checkedFilters.includes(id);
    const showFilter = title?.toLowerCase().includes(keywordReq)
      && (index < 5 || showAllFilters || selected);

    return (
      <span
        key={'filter-group-' + index}
        className={
          classnames(
            'sui-capitalize',
            { 'sui-hidden': !showFilter }
          )
        }
      >
        <SelectionControlLabel
          value="a"
          label={title}
        >
          <Checkbox
            checked={selected}
            onChange={() => toggleChecked(id)}
            value={id}
            disabled={loading}
          />
        </SelectionControlLabel>
      </span>
    );
  });

  const updateSearch = (event) => {
    setKeywordReq(event.target.value.toLowerCase());
    setShowAllFilters(true);
  };

  return (
    <div
      data-testid="filter-group"
    >
      <Accordion defaultExpanded={false}>
        <AccordionHeader>
          {header}
        </AccordionHeader>
        <AccordionBody>
          <div>
            {renderSearch
              && (
                <div
                  className="sui-mb-3"
                >
                  <TextField
                    fullWidth
                    id="basic-input"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search color="subtle" />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search"
                    onChange={updateSearch}
                  />
                </div>
              )}
            <div
              className="sui-flex sui-flex-col sui-gap-2"
            >
              {renderedFilters}
            </div>
            {renderShowAllButton
              && (
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <div
                  data-testid="filter-show-button"
                  role="button"
                  className={
                    classnames(
                      'sui-flex sui-justify-center sui-items-center sui-gap-2 sui-cursor-pointer',
                    )
                  }
                  onClick={() => { setShowAllFilters(!showAllFilters); }}
                >
                  <Link
                    component="button"
                  >
                    {showAllText}
                  </Link>
                  {
                    showAllFilters ? <ArrowUp size="small" />
                      : <ArrowDown size="small" />
                  }
                </div>
              )}
          </div>
        </AccordionBody>
      </Accordion>
    </div>
  );
};

FilterGroup.propTypes = {
  header: string,
  filters: oneOfType([string, instanceOf(Array)]).isRequired,
  toggleChecked: func.isRequired,
  checkedFilters: arrayOf(string).isRequired,
  loading: bool
};

FilterGroup.defaultProps = {
  header: '',
  loading: false
};

export { FilterGroup };