import React, { useState, useEffect } from 'react';
import {
  arrayOf,
  bool as boolType,
  number as numberType,
  params,
  string as stringType,
  useDataModel,
  shape
} from '@thd-nucleus/data-sources';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerTitle,
  IconButton,
  Link,
  SkeletonLine,
  Typography
} from '@one-thd/sui-atomic-components';
import { ArrowBack, ArrowForward, Close } from '@one-thd/sui-icons';

const ArticleDrawer = () => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('article-drawer.ready'); }, []);

  const { data } = useDataModel('categories', {});

  const [open, setOpen] = useState(false);
  const [categoryID, setCategoryID] = useState(null);
  const [topicsForCategory, setTopicsForCategory] = useState([]);
  const [topicName, setTopicName] = useState(null);

  const { data: topicsData, loading } = useDataModel('topic', {
    variables: { categoryID },
    skip: !categoryID
  });

  useEffect(() => {
    if (topicsData?.topic && categoryID) {
      const topics = [...topicsData.topic].sort((topicA, topicB) => topicA.title.localeCompare(topicB.title)
      );
      setTopicsForCategory((prevTopics) => {
        const isSame = JSON.stringify(prevTopics) === JSON.stringify(topics);
        return isSame ? prevTopics : topics;
      });
    }
  }, [topicsData, categoryID]);

  const clickListener = (category) => {
    if (category.title !== topicName) {
      setTopicName(category.title);
    }
    if (category.id !== categoryID) {
      setCategoryID(category.id);
    }
  };

  const toggleDrawer = (isOpen) => {
    setTopicName(null);
    setOpen(isOpen);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab' || event.key === 'Shift') {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!data) return null;

  const categories = data?.categories
    ? [...data.categories].sort((categoryA, categoryB) => categoryA.title.localeCompare(categoryB.title))
    : [];

  let allCategoriesList = Array.isArray(categories) && categories.length ? categories : [];

  const drawerList = () => {
    let list = topicName ? topicsForCategory : allCategoriesList;

    if (loading) {
      return (
        <div className="sui-flex sui-flex-col sui-gap-6">
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
        </div>
      );
    }

    if (!Array.isArray(list)) list = [];

    return (
      <div className="sui-flex sui-flex-col">
        {list.map((item, index) => (
          <div
            key={index}
          >
            <div
              className="sui-flex sui-flex-row sui-justify-between sui-w-full sui-py-6"
            >
              {!topicName ? (
                <div
                  className="sui-flex sui-justify-between sui-cursor-pointer sui-w-full"
                  role="button"
                  tabIndex={0}
                  onClick={() => clickListener(item)}
                >
                  <Typography variant="body-base">
                    {item.title || item}
                  </Typography>
                  <ArrowForward size="small" />
                </div>
              ) : (
                <Link href={item.url} underline="none">
                  {item.title || item}
                </Link>
              )}
            </div>
            {
              index + 1 < list.length
              && <hr className="sui-w-full" />
            }
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      data-testid="article-drawer"
      data-component="ArticleDrawer"
    >
      <div>
        <Drawer open={open} onClose={() => toggleDrawer(false)}>
          {topicName ? (
            <DrawerHeader
              data-testid="categories-drawer-header-topic"
              startAdornment={(
                <Button
                  onClick={() => setTopicName(null)}
                  aria-label="back"
                  variant="ghost"
                  startIcon={ArrowBack}
                >
                  Back
                </Button>
              )}
              endAdornment={(
                <IconButton
                  aria-label="close"
                  onClick={() => toggleDrawer(false)}
                  icon={Close}
                />
              )}
            >
              <DrawerTitle>
                {topicName}
              </DrawerTitle>
            </DrawerHeader>
          ) : (
            <DrawerHeader
              data-testid="categories-drawer-header-all"
              title="All Categories"
              onClose={() => toggleDrawer(false)}
            />
          )}
          <DrawerBody>
            {drawerList()}
          </DrawerBody>
        </Drawer>
      </div>
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          data-testid="all-categories-drawer-btn"
          href="#"
          onClick={() => toggleDrawer(true)}
        >
          All Categories
        </Link>
      </div>
    </div>
  );
};

ArticleDrawer.propTypes = {
};

ArticleDrawer.displayName = 'ArticleDrawer';

ArticleDrawer.dataModel = {
  categories: arrayOf(shape({
    title: stringType(),
    id: stringType(),
    url: stringType()
  })),
  topic: arrayOf(params({ categoryID: stringType().isRequired() }).shape({
    title: stringType(),
    id: stringType(),
    type: stringType(),
    count: numberType(),
    url: stringType(),
    isCurrentPage: boolType()
  }))
};

export { ArticleDrawer };