import { useEffect } from 'react';

export function useWindowEffects({
  popState,
  handlePopState,
  mounted,
  setMounted,
  variables,
  canonicalURL,
  completePath,
}) {
  useEffect(() => {
    window.addEventListener('popstate', handlePopState);
    window.history.replaceState({ popVariables: variables }, '', completePath);
    setMounted(true);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  useEffect(() => {
    if (!popState && mounted) {
      window.history.pushState({ popVariables: variables }, '', completePath);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [canonicalURL]);
}
