import React from 'react';
import { string, number, shape, arrayOf } from 'prop-types';
import classNames from 'classnames';
import {
  arrayOf as arrayType,
  bool as boolType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { ArticleTileForAlp } from '@thd-olt-component-react/article-tile';

const ArticleTilesContainer = ({ variables }) => {
  const { data, loading, error } = useDataModel('articles', {
    variables,
  });

  if (!data || loading || error) {
    return null;
  }

  const summaries = data?.articles?.summaries || [];

  const tiles = summaries.map((tile) => ({
    ...tile,
    canonicalURL: tile?.url,
    imageURL: tile?.thumbnailImage,
    hasVideo: !!tile?.hasVideoPreview,
    articleType: tile?.type?.toUpperCase(),
  }));

  return (
    <div
      className={classNames(
        'sui-grid sui-grid-cols-1',
        'sm:sui-grid-cols-2 md:sui-grid-cols-3 lg:sui-grid-cols-4',
        'sui-gap-4 md:sui-gap-6 lg:sui-gap-8'
      )}
      data-testid="article-tiles-container"
    >
      {tiles.map((tile, i) => (
        <ArticleTileForAlp key={`tile-${i}`} tile={tile} />
      ))}
    </div>
  );
};

ArticleTilesContainer.propTypes = {
  variables: shape({
    categoryID: string.isRequired,
    filters: arrayOf(string),
    limit: number,
    page: number,
    topicID: string,
    type: string
  }).isRequired
};

ArticleTilesContainer.displayName = 'ArticleTilesContainer';

ArticleTilesContainer.dataModel = {
  articles: params({
    categoryID: stringType().isRequired(),
    topicID: stringType(),
    page: numberType(),
    limit: numberType(),
    type: stringType(),
    filters: arrayType(stringType()),
  }).shape({
    summaries: arrayType(
      shapeType({
        url: stringType(),
        tagline: stringType(),
        thumbnailImage: stringType(),
        type: stringType(),
        title: stringType(),
        difficulty: shapeType({
          display: stringType(),
        }),
        duration: shapeType({
          display: stringType(),
        }),
        hasVideoPreview: boolType(),
      })
    ),
  }),
};

export { ArticleTilesContainer };
