import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import {
  Clock,
  Ruler,
} from '@one-thd/sui-icons';

function ProjectGuideForAlp({ difficulty, duration }) {
  return (
    <div
      data-testid="project-guide"
      className="sui-flex sui-flex-col sui-gap-2"
    >
      <div className="sui-flex sui-gap-2">
        <Ruler />

        <Typography variant="body-base">
          <span className="sui-font-bold">
            Skill Level:
          </span>
          {' '}
          {difficulty}
        </Typography>
      </div>

      <div className="sui-flex sui-gap-2">
        <Clock />

        <Typography variant="body-base">
          <span className="sui-font-bold">
            Time:
          </span>
          {' '}
          {duration}
        </Typography>
      </div>
    </div>
  );
}

ProjectGuideForAlp.propTypes = {
  difficulty: string.isRequired,
  duration: string.isRequired,
};

export { ProjectGuideForAlp };
