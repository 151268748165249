import React, { useContext } from 'react';
import { shape, number, string, arrayOf } from 'prop-types';
import { RedirectTag, useHelmet } from '@thd-nucleus/thd-helmet';
import {
  params,
  arrayOf as arrayType,
  string as stringType,
  number as numberType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { RedirectContext } from './RedirectContext';
import { isALPCanonical, isNotFound } from './rules/alp-redirect-rules';
import { handleErrors, setUrlContext, ERROR_HANDLING_EXPERIENCE } from '../utils/redirector-utils';

const alpHandler = ({ data, error, props }) => {

  let path = props.path;

  path = setUrlContext(path, '/c/');

  const [, queryParams = ''] = path?.split('?') || '';

  const debug = queryParams.indexOf('debug') > -1;

  const errors = handleErrors(error, ERROR_HANDLING_EXPERIENCE.GENERIC);
  if (errors) {
    return new RedirectTag(errors, props.bypass);
  }
  const notFound = isNotFound({ data });
  if (notFound) {
    return new RedirectTag(notFound, props.bypass);
  }

  const alpCanonical = isALPCanonical({ data, path });
  if (alpCanonical && !debug) {
    return new RedirectTag(alpCanonical, props.bypass);
  }
  return null;
};

export const ALPRedirector = ({ variables, path }) => {

  const { bypass } = useContext(RedirectContext);
  const { data, error } = useDataModel('articles', {
    variables,
  });

  useHelmet('alpRedirector', { data, error, props: { bypass, path } }, alpHandler, []);

  return null;
};

ALPRedirector.displayName = 'ALPRedirector';

ALPRedirector.propTypes = {
  variables: shape({
    categoryID: string.isRequired,
    topicID: string,
    page: number,
    limit: number,
    type: string,
    filters: arrayOf(string)
  }).isRequired,
  path: string.isRequired
};

ALPRedirector.defaultProps = {};

ALPRedirector.dataModel = {
  articles: params({
    categoryID: stringType(),
    topicID: stringType(),
    page: numberType(),
    limit: numberType(),
    type: stringType(),
    filters: arrayType(stringType()),
  }).shape({
    canonicalURL: stringType()
  }),
};
