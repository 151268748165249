import React from 'react';
import { object } from 'prop-types';

import {
  Card,
  CardActionArea,
  CardBody,
  CardMedia,
  CardTitle,
  Typography,
} from '@one-thd/sui-atomic-components';
import { ProjectGuideForAlp } from './subcomponents/ProjectGuideForAlp';

const ArticleTileForAlp = ({ tile }) => {
  const {
    articleType = 'AP',
    canonicalURL,
    difficulty,
    duration,
    hasVideo,
    imageURL,
    introduction,
    tagline,
    title,
  } = tile;

  const articleTypeNames = {
    AB: 'Buying Guide',
    AI: 'Inspiration Guide',
    AH: 'Project Guide',
    AP: 'Influencer Guide'
  };

  const isProjectGuide = articleType === 'AH';

  return (
    <Card
      href={canonicalURL}
      data-testid="article-tile-for-alp"
      CardContentProps={{
        className: 'sui-h-full sui-w-full sui-flex sui-flex-col sui-gap-4 sui-p-4 sui-paper-outlined'
      }}
    >
      <CardMedia
        src={imageURL}
        alt="Article Image"
        aspect="wide"
      />
      <CardTitle
        eyebrow={(
          <Typography variant="h5" weight="display" height="snug" color="brand">
            {articleTypeNames[articleType]}
          </Typography>
        )}
        header={title}
      />
      <CardBody>
        <Typography lineClamp="2">{tagline}</Typography>
      </CardBody>
      {
        isProjectGuide
        && (
          <ProjectGuideForAlp difficulty={difficulty?.display} duration={duration?.display} />
        )
      }
    </Card>
  );
};

ArticleTileForAlp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tile: object.isRequired,
};

ArticleTileForAlp.displayName = 'ArticleTileForAlp';

ArticleTileForAlp.dataModel = {};

export { ArticleTileForAlp };
